.u-flex-wrap {
	&--no {
		flex-wrap: nowrap;
	}
}

.u-flex {
	display: flex;
	justify-content: space-between;

	&.even {
		flex-direction: row-reverse;
	}

	.u-flex-image {
		margin-bottom: 50px;
		width: 40%;
		height: auto;

		img {
			width: 100%;
			height: auto;
		}
	}

	.u-flex-text {
		margin-bottom: 50px;
		width: 55%;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;

		&.even {
			flex-direction: column;
		}

		.u-flex-image {
			margin-bottom: 30px;
			width: 100%;
		}

		.u-flex-text {
			margin-bottom: 30px;
			width: 100%;
		}
	}
}