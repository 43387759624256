.p-single {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  background-color: #fff;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  font-weight: normal;
  text-align: left;
  word-break: break-all;

  @media screen and (max-width: 768px) {
    padding-bottom: 50px;
  }

  p,
  ul,
  ol,
  dl {
    margin-bottom: 20px;
  }

  h2 {
    border-left: 10px solid $color-yellow;
    margin: 0 0 36px;
    padding: 0 10px;
    font-size: 24px;
    line-height: 36px;
  }

  img {
    max-width: 100%;
    width: unset;
    height: auto;
  }

  &__title {
    margin: 0 8.33%;
    padding-top: 80px;
    max-width: 1000px;
    font-size: 40px;
    line-height: 60px;

    @media screen and (max-width: 768px) {
      margin: 50px 20px 0;
      padding-top: 50px;
      font-size: 32px;
      line-height: 48px;
    }
  }

  &__cat {
    display: inline-block;
    margin: 30px 20px 30px 8.33%;
    padding: 0 10px;
    background-color: $color-black;
    font-size: 12px;
    line-height: 24px;
    color: $color-yellow;

    @media screen and (max-width: 768px) {
      margin: 30px 20px;
    }
  }

  &__date {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__main {
    max-width: 1000px;
    margin: 0 8.33%;
    padding-top: 60px;
    border-top: 4px solid $color-black;

    @media screen and (max-width: 768px) {
      margin: 0 20px;
    }
  }

  &__sub-title {
    border-left: 10px solid $color-yellow;
    margin: 0 0 36px;
    padding: 0 10px;
    font-size: 24px;
    line-height: 36px;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 80px;

    &-item {
      width: 300px;
      position: relative;
      margin: 0 15px;

      &--prev::before,
      &--next::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $color-yellow;
        font-family: "Font Awesome 5 Free";
        font-size: 19px;
      }

      &--prev {
        &::before {
          content: '\f053';
          left: 30px;
        }
      }

      &--next {
        &::after {
          content: '\f054';
          right: 30px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      margin: 50px 0;

      &-item {
        max-width: 100%;
        margin: 0 auto;

        &--prev {
          margin-bottom: 30px;
        }
      }
    }
  }
}

/*-----------------------------
ギャラリー
------------------------------*/
.gallery {
  /** ギャラリーを囲むボックス **/
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px !important;
  overflow: hidden;
}

.gallery br {
  display: none;
}

/** 自動で挿入される clearfix の余白解除 **/

.gallery-item {
  /** 画像共通のスタイル **/
  margin-bottom: 0 !important;
}

.gallery-icon {
  /** 画像を囲む dt のスタイル **/
  text-align: center;
}

.gallery-icon img {
  width: 100%;
  height: auto;
  margin-bottom: 10px !important;
}

.gallery-caption {
  /** キャプション **/
  color: #222;
  font-size: 12px;
  margin: 0 0 10px;
  text-align: center;
}

.gallery-columns-1 .gallery-item {
  /** カラムなし **/
  width: 100%;
  margin-right: 0;
}

.gallery-columns-2 .gallery-item {
  /** 2カラム **/
  width: 49%;
}

.gallery-columns-3 .gallery-item {
  /** 3カラム **/
  width: 33%;
}

.gallery-columns-3 {
  &::after {
    display: block;
    content: "";
    width: 33%;
  }
}

.gallery-columns-4 {
  &::before {
    display: block;
    content: "";
    width: 24%;
    order: 1;
  }

  &::after {
    display: block;
    content: "";
    width: 24%;

  }
}

.gallery-columns-4 .gallery-item {
  /** 4カラム **/
  width: 24%;
}

@media screen and (max-width: 768px) {

  /* 768px以下用の記述 */
  .gallery-columns-3 .gallery-item,
  .gallery-columns-4 .gallery-item {
    width: 49%;
  }
}

@media screen and (max-width: 480px) {

  /* 768px以下用の記述 */
  .gallery-columns-2 .gallery-item,
  .gallery-columns-3 .gallery-item,
  .gallery-columns-4 .gallery-item {
    width: 100%;
  }
}