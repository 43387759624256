.c-title {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  
  &__sub {
    margin-bottom: 15px;
    font-size: 20px;
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  }

  &__main {
    display: inline-block;
    padding: 0 15px;
    background: linear-gradient(transparent 66%, $color-yellow 66%);
    font-size: 90px;
    font-weight: normal;
    line-height: 90px;
    font-family: 'Anton', sans-serif;

    @media screen and (max-width: 768px) {
      font-size: calc(2.75rem + ((1vw - 3.2px) * 10.2679));
    }
  }
}
