.p-humberger {
	font-size: 0;

	/*ハンバーガーアイコンをCSSだけで表現*/
	&__icon {
		display: inline-block;
		width: 35px;
		height: 40px;
		vertical-align: middle;

		&-line {
			display: block;
			position: absolute;
			transition: all 0.3s;
			transform: rotate(0deg);
			width: 34px;
			/*長さ*/
			height: 6px;
			/*線の太さ*/
			background: $color-yellow;
			color: $color-yellow;
			cursor: pointer;

			&--middle {
				transform: translateY(12px);
				background: #fff;
			}

			&--bottom {
				background-color: transparent;
				font: 16px/23px Anton;
				height: 16px;
				transform: translateY(22px);
			}

		}
	}

	&__input {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	/*チェックが入ったらハンバーガーアイコン変更*/
	&__input:checked~&__icon {
		.p-humberger__icon-line {
			&--top {
				transform: rotate(45deg);
			}

			&--middle {
				transform: rotate(-45deg);
			}
		}
	}
}