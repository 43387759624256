.p-slider {
  margin-bottom: 74px;
  //text-align: center;

  @media screen and (max-width: 768px) {
    margin: 50px 0;
  }


  &__inner {
    //display: inline-block;
    position: relative;
    margin-top: -30px;
    padding: 0 calc(1.25rem + ((1vw - 7.68px) * 32.0313));

    @media screen and (max-width: 768px) {
      margin-top: 0px;
      padding: 0 calc(0.625rem + ((1vw - 3.2px) * 7.8125));
    }
  }

  &__slide-btn {
    position: absolute;
    top: 35%;
    width: 20px;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__list {
    display: flex;
    //justify-content: center;
    align-items: center;
    text-align: left;
  }

  &__item {
    margin: 0 8.5px;
  }

  &__more-btn {
    margin: 52px auto 0;
  }
}

// slick使うことが前提
.slide-arrow {
  width: 20px;
}
.prev-arrow {
  margin-right: calc(1.0625rem + ((1vw - 7.68px) * 2.1701));;
  @media screen and (min-width: 1921px) {
    margin-right: 42px;
  }
	@media screen and (max-width: 768px) {
    margin-right: calc(1.0625rem + ((1vw - 3.2px) * 2.9018));
	}
}
.next-arrow {
  margin-left: calc(1.0625rem + ((1vw - 7.68px) * 2.1701));;
  @media screen and (min-width: 1921px) {
    margin-left: 42px;
  }
	@media screen and (max-width: 768px) {
    margin-left: calc(1.0625rem + ((1vw - 3.2px) * 2.9018));
	}
}
