.c-btn {
  display: block;
  width: 240px;
  height: 62px;
  background-color: #000;
  border-radius: 31px;
  color: #fff;
  font-size: 16px;
  line-height: 62px;
  text-align: center;

  &--yellow {
    background-color: $color-yellow;
    color: $color-black;
  }

  &--white {
    border: 1px solid $color-black;
    background-color: #fff;
    color: $color-black;
  }

  &--big {
    width: 490px;
  }
}
