@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* add at 2018-1-8 */
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* add at 2018-8-28 border-box */
*, *:before, *:after {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: #1a1a1a;
  text-decoration: none;
}

a:hover {
  opacity: .7;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

body {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: #1a1a1a;
}

body.modal-open {
  overflow: hidden;
}

body.show-menu {
  overflow: hidden;
}

.hiragino_sans {
  font-family: "Hiragino Sans", serif;
}

.hiragino_kaku {
  font-family: "Hiragino Kaku Gothic ProN", serif;
}

.yugothic {
  font-family: "Yu Gothic Medium", "Yu Gothic", serif;
}

.meiryo {
  font-family: "Meiryo", "Yu Gothic", serif;
}

.w__n {
  font-weight: normal;
}

.w__b {
  font-weight: bold;
}

.w__3 {
  font-weight: 300;
}

.w__4 {
  font-weight: 400;
}

.w__6 {
  font-weight: 600;
}

.w__7 {
  font-weight: 700;
}

.l-main {
  margin-top: 91px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .l-main {
    margin-top: 80px;
  }
}

.l-main--gray {
  background-color: #f2f2f2;
}

.l-main--single {
  padding: 80px 0 100px;
}

@media screen and (max-width: 768px) {
  .l-main--single {
    padding: 20px;
  }
}

.c-btn {
  display: block;
  width: 240px;
  height: 62px;
  background-color: #000;
  border-radius: 31px;
  color: #fff;
  font-size: 16px;
  line-height: 62px;
  text-align: center;
}

.c-btn--yellow {
  background-color: #f4e827;
  color: #1a1a1a;
}

.c-btn--white {
  border: 1px solid #1a1a1a;
  background-color: #fff;
  color: #1a1a1a;
}

.c-btn--big {
  width: 490px;
}

.c-card {
  max-width: 186px;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  font-size: 0;
  font-weight: bold;
  text-align: left;
}

.c-card__img {
  width: 100%;
}

.c-card__cat {
  display: inline-block;
  margin: 10px 0 8px;
  padding: 0 10px;
  background-color: #1a1a1a;
  font-size: 12px;
  line-height: 24px;
  color: #f4e827;
}

.c-card__date {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}

.c-card__text {
  font-size: 16px;
  font-weight: normal;
}

.c-cats {
  max-width: 1000px;
  margin: auto;
  padding: 30px 0;
  background-color: #fff;
}

.c-cats__title {
  font-size: 24px;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  line-height: 35px;
}

.c-cats__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
}

.c-cats__list-item {
  margin: 0 15px;
}

.c-cats__list-item .is-active {
  color: #f4e827;
}

.c-effort {
  background-blend-mode: luminosity;
  background: no-repeat center/cover url("../img/effort_bg.jpg");
  background-color: #e3e3e3;
}

.c-effort__inner {
  padding: 100px 0;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.c-effort__title {
  display: inline-block;
  width: 340px;
  line-height: 80px;
  color: #fff;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .c-effort__title {
    max-width: 100%;
  }
}

.c-effort__title--en {
  margin-right: 17px;
  background-color: #1a1a1a;
  font-size: 44px;
  font-weight: normal;
  font-family: 'Anton', sans-serif;
}

@media screen and (max-width: 698px) {
  .c-effort__title--en {
    margin-right: 0;
  }
}

.c-effort__title--jp {
  background-color: #f4e827;
  color: #1a1a1a;
  font-size: 28px;
}

.c-effort__title--color-white {
  color: #fff;
}

.c-effort__title--color-yellow {
  color: #f4e827;
}

.c-effort__movie {
  width: 1000px;
  max-width: 100%;
  margin: 80px auto 50px;
}

@media screen and (max-width: 768px) {
  .c-effort__movie {
    max-width: 100%;
  }
}

.c-effort__copy {
  display: inline-block;
  width: 284px;
  margin-right: 42px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .c-effort__copy {
    width: 354px;
    max-width: calc(100% - 40px);
    margin: 0 20px 70px;
  }
}

.c-effort__msg {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  line-height: 31px;
  text-align: left;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .c-effort__msg {
    margin: 0 20px;
  }
}

.c-effort__msg-inner {
  display: flex;
  flex-direction: column;
}

.c-effort__sign {
  width: 191px;
  margin-left: auto;
}

.c-footer {
  border-top: 1px solid #c1c1c1;
}

.c-footer__partners {
  margin: 100px auto 40px;
  padding: 0 30px 0;
  max-width: 1000px;
}

@media screen and (max-width: 768px) {
  .c-footer__partners {
    padding: 0 20px 0;
  }
}

.c-footer__partners-dt {
  border-bottom: 1px solid #1a1a1a;
  font-family: 'Anton', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
}

.c-footer__partners-dd {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 45px 0;
}

@media screen and (max-width: 768px) {
  .c-footer__partners-dd {
    padding: 66px 0 60px;
  }
}

.c-footer__partners-item {
  width: auto;
  zoom: .4347;
}

@media screen and (max-width: 768px) {
  .c-footer__partners-item {
    margin: 0 42px 10px;
    zoom: .5;
  }
}

.c-footer__footer {
  padding: 50px 0;
  background-color: #1a1a1a;
  color: #c1c1c1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .c-footer__footer {
    padding: 50px 0 100px;
  }
}

.c-footer__footer-logo {
  width: 183px;
  margin-right: 30px;
}

.c-footer__footer-uep3 {
  width: 102px;
}

.c-footer__footer-title {
  margin: 20px 0 16px;
  font-family: 'Anton', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
}

.c-footer__footer-address {
  font-size: 15px;
  line-height: 26px;
}

.c-footer__footer-copy {
  margin: 40px 0 52px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 100px auto;
  padding: 50px 0;
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate(0, -125%);
  -ms-transform: translate(0, -125%);
  -o-transform: translate(0, -125%);
  transform: translate(0, -125%);
  background-color: #fff;
  font-size: 20px;
}

.modal.in {
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.modal-footer .c-btn:first-child {
  margin-right: 30px;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.modal-backdrop.in {
  opacity: .5;
}

.c-sns {
  display: flex;
  align-items: center;
}

.c-sns__btn {
  margin-right: 29px;
  font-size: 55px;
}

.c-sns__btn:first-child {
  margin-left: 14px;
}

.c-sns__btn--twitter {
  font-size: 48px;
}

.c-sns__btn--youtube {
  font-size: 46px;
}

@media screen and (max-width: 768px) {
  .c-sns {
    position: fixed;
    bottom: 50px;
    left: 0;
    z-index: 3;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: rgba(26, 26, 26, 0.8);
  }
  .c-sns__btn {
    color: #fff;
    font-size: 26px;
    line-height: 40px;
  }
  .c-sns__btn--twitter {
    font-size: 19px;
  }
  .c-sns__btn--youtube {
    font-size: 18px;
  }
}

.c-title {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.c-title__sub {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
}

.c-title__main {
  display: inline-block;
  padding: 0 15px;
  background: linear-gradient(transparent 66%, #f4e827 66%);
  font-size: 90px;
  font-weight: normal;
  line-height: 90px;
  font-family: 'Anton', sans-serif;
}

@media screen and (max-width: 768px) {
  .c-title__main {
    font-size: calc(2.75rem + ((1vw - 3.2px) * 10.2679));
  }
}

.c-title-label {
  width: 100%;
  background-color: #1a1a1a;
  color: #fff;
  font-size: 30px;
  line-height: 66px;
  font-weight: normal;
  font-family: 'Anton', sans-serif;
}

.c-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 82px;
  height: 82px;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .c-to-top {
    bottom: 104px;
    width: 10.67708vw;
    height: 10.67708vw;
  }
}

.p-about {
  padding: 100px 0;
  background-color: #f2f2f2;
}

.p-about__sub-title-img {
  transform: translateY(-50%);
}

.p-about__sub-title-img--logo {
  width: 413px;
}

.p-about__sub-title-img--uep3 {
  width: 291px;
}

@media screen and (max-width: 768px) {
  .p-about__sub-title-img {
    max-width: 100%;
  }
}

.p-about__msg {
  margin: 60px 0 153px;
  font-size: 20px;
  line-height: 40px;
}

.p-about__inner {
  max-width: 1200px;
  margin: 0 auto 207px;
  padding: 0 5.20833% 100px;
  background-color: #fff;
}

.p-about__inner:last-child {
  margin-bottom: 0;
}

.p-about__ss-title {
  margin: 60px 0 30px;
  border-bottom: 6px solid #f4e827;
  font-size: 26px;
  line-height: 44px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .p-about__ss-title--sp {
    margin-top: 20px;
  }
}

.p-about__text {
  margin-bottom: 20px;
  font-weight: 300;
  text-align: left;
}

.p-about__contact-btn {
  width: auto;
  max-width: 900px;
  height: 88px;
  margin: 40px auto 0;
  border-radius: 44px;
  font-size: 27px;
  line-height: 88px;
}

@media screen and (max-width: 768px) {
  .p-about__contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 22px;
    line-height: 1;
  }
}

.p-about__philosophy-img {
  max-width: 846px;
  margin: 30px auto 50px;
}

.p-about__coach-img {
  max-width: 1000px;
  margin: 0 auto;
}

.p-about__faq {
  text-align: left;
}

.p-about__faq-q {
  display: flex;
  align-items: center;
  padding: 8px 30px;
  border: 1px solid #1a1a1a;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .p-about__faq-q {
    font-size: 20px;
  }
}

.p-about__faq-q::before {
  content: 'Q';
  margin-right: 30px;
  color: #f4e827;
  font-family: 'Anton', sans-serif;
  font-size: 40px;
  font-weight: normal;
  line-height: 59px;
}

.p-about__faq-q::after {
  content: '\f078';
  margin-left: auto;
  color: #f4e827;
  font-family: "Font Awesome 5 Free";
  font-size: 19px;
}

.p-about__faq-a {
  display: none;
  padding: 30px;
  border: 1px solid #1a1a1a;
  border-top: none;
  background-color: #fafafa;
  font-family: "Hiragino Sans GB", "Hiragino Sans GB W3", "STXihei", "Microsoft YaHei", "微软雅黑", 'SimSun', "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic Medium", "Yu Gothic", "Meiryo", "Arial", sans-serif;
  webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.p-about__base-price {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  border: 1px solid #1a1a1a;
  border-bottom: none;
  line-height: 66px;
}

.p-about__base-price-dt {
  width: 31.4%;
  background-color: #1a1a1a;
  color: #fff;
}

.p-about__base-price-dd {
  width: 68.6%;
  border-bottom: 1px solid #1a1a1a;
}

.p-about__base-price-dd + .p-about__base-price-dt {
  border-top: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .p-about__base-price {
    font-size: 14px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .p-about__base-price-dd {
    padding-left: 19px;
    text-align: left;
  }
}

.p-about__table {
  width: 100%;
  line-height: 60px;
  table-layout: fixed;
}

.p-about__table-head {
  border: 1px solid #1a1a1a;
  background-color: #1a1a1a;
  color: #fff;
}

.p-about__table-head:not(:last-child) {
  border-right: 1px solid #fff;
}

.p-about__table-body {
  border: 1px solid #1a1a1a;
}

@media screen and (max-width: 768px) {
  .p-about__table {
    font-size: 14px;
    line-height: 1;
  }
  .p-about__table-head {
    height: 45px;
    vertical-align: middle;
  }
  .p-about__table-body {
    height: 45px;
    vertical-align: middle;
  }
}

.p-about-group {
  padding: 100px 0;
}

.p-about-group__text {
  margin: 60px auto 80px;
  font-size: 20px;
  line-height: 40px;
}

@media screen and (max-width: 768px) {
  .p-about-group__text {
    padding: 0 20px;
  }
}

.p-about-group__effort-dream {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1000px;
  max-height: 290px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .p-about-group__effort-dream {
    display: block;
    max-height: none;
    padding: 0 20px;
  }
}

.p-about-group__effort-dream-dt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 74px;
  line-height: 74px;
  font-size: 30px;
}

.p-about-group__effort-dream-dt--black {
  background-color: #1a1a1a;
  color: #f4e827;
}

.p-about-group__effort-dream-dt--yellow {
  background-color: #f4e827;
  color: #1a1a1a;
}

.p-about-group__effort-dream-dt--white {
  color: #fff;
}

.p-about-group__effort-dream-dt--en {
  font-size: 40px;
  font-weight: normal;
  font-family: 'Anton', sans-serif;
}

@media screen and (max-width: 768px) {
  .p-about-group__effort-dream-dt {
    width: 100%;
  }
}

.p-about-group__effort-dream-dd {
  width: 50%;
  padding: 41px 50px 0;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .p-about-group__effort-dream-dd {
    width: 100%;
    padding: 34px 20px 50px;
  }
}

.p-about-group__teikan {
  margin: 80px auto;
}

@media screen and (max-width: 768px) {
  .p-about-group__teikan {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .p-about-group__btn-list {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.p-about-group__menu {
  max-width: 1000px;
  margin: 40px auto 0;
  padding: 31px 35px;
  background-color: #f2f2f2;
}

.p-about-group__menu-list {
  display: flex;
}

@media screen and (max-width: 768px) {
  .p-about-group__menu-list {
    display: block;
  }
}

.p-about-group__menu-item {
  width: 50%;
  padding: 29px 0 9px;
}

.p-about-group__menu-item:first-child {
  padding-right: 45.5px;
  border-right: 2px solid #fff;
}

.p-about-group__menu-item:last-child {
  padding-left: 45.5px;
}

@media screen and (max-width: 768px) {
  .p-about-group__menu-item {
    width: 420px;
    max-width: 100%;
    margin: auto;
  }
  .p-about-group__menu-item:first-child {
    border: none;
    padding-right: 0;
  }
  .p-about-group__menu-item:last-child {
    padding-left: 0;
  }
}

.p-about-group__menu-title {
  margin-bottom: 50px;
  font-size: 24px;
}

.p-about-group__menu-btn {
  width: 100%;
  margin-bottom: 20px;
}

.p-access {
  padding-top: 100px;
  background-color: #f2f2f2;
}

.p-access__address {
  margin: 60px auto 22px;
  font-size: 20px;
}

.p-access__access {
  margin-bottom: 60px;
  font-weight: 300;
}

.p-archive {
  padding: 100px 0 100px;
}

@media screen and (max-width: 768px) {
  .p-archive {
    padding: 100px 20px 100px;
  }
}

.p-archive__title {
  margin-bottom: 80px;
}

.p-archive__cats {
  margin: -10px auto 60px;
}

@media screen and (max-width: 768px) {
  .p-archive__cats {
    width: 100%;
    padding-right: calc(1.25rem + ((1vw - 3.2px) * 25.6696));
    padding-left: calc(1.25rem + ((1vw - 3.2px) * 25.6696));
  }
}

.p-archive__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1017px;
  margin: 0 auto;
}

.p-archive__list--top {
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .p-archive__list {
    line-height: 32px;
  }
  .p-archive__list--top {
    flex-wrap: wrap;
  }
  .p-archive__list::after, .p-archive__list::before {
    content: '';
    display: block;
    flex: 0 1 29%;
    margin: 0 8.5px;
  }
  .p-archive__list::before {
    order: 1;
  }
}

@media screen and (max-width: 480px) {
  .p-archive__list::after, .p-archive__list::before {
    flex: 0 1 43%;
  }
}

.p-archive__item {
  margin: 0 8.5px 52px;
}

@media screen and (max-width: 768px) {
  .p-archive__item {
    flex: 0 1 29%;
  }
}

@media screen and (max-width: 480px) {
  .p-archive__item {
    max-width: none;
    flex: 0 1 43%;
  }
}

@media screen and (max-width: 480px) {
  .p-archive__item--top {
    flex: 0 1 28%;
  }
}

@media screen and (max-width: 414px) {
  .p-archive__item--top {
    flex: 0 1 98%;
  }
}

.p-archive__more {
  max-width: 1000px;
  margin: 0 auto;
}

.p-archive__more-btn {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .p-archive__more-btn {
    margin-right: auto;
  }
}

.p-archive__pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
}

.p-archive__pagination-item {
  background-color: #fff;
  line-height: 50px;
}

.p-archive__pagination-item:not(:last-child) {
  margin-right: 20px;
}

.p-archive__pagination-item.is-active {
  background-color: #f4e827;
}

.p-archive__pagination-item-inner {
  display: block;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .p-archive__pagination-item {
    line-height: calc(16px + 5.2vw);
  }
  .p-archive__pagination-item:not(:last-child) {
    margin-right: 2.7%;
  }
  .p-archive__pagination-item-inner {
    padding: 0 2.6vw;
  }
}

.p-btn-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 50px auto 0;
}

@media screen and (max-width: 768px) {
  .p-btn-list {
    flex-direction: column;
    align-items: center;
  }
}

.p-btn-list::after {
  content: '';
  width: 100%;
}

.p-btn-list__item {
  margin-bottom: 20px;
  width: calc(50% - 10px);
}

.p-btn-list__item:nth-child(n+3) {
  order: 1;
}

.p-btn-list__item:nth-child(odd) {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .p-btn-list__item {
    width: 490px;
    max-width: 100%;
  }
  .p-btn-list__item:nth-child(odd) {
    margin-right: 0;
  }
}

.p-btn-list__btn {
  width: 100%;
}

.p-contact {
  margin: auto;
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  .p-contact {
    padding: 100px 20px;
  }
}

.p-contact__form {
  max-width: 1000px;
  margin: auto;
}

.p-contact__text {
  margin: 60px auto 80px;
  font-size: 20px;
  line-height: 40px;
}

.p-contact__msg {
  margin: 50px 0 30px;
}

.p-contact__dl-btn {
  width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .p-contact__dl-btn {
    max-width: 100%;
  }
}

.p-contact__label {
  display: flex;
  align-items: center;
  margin-top: 50px;
  font-size: 26px;
}

@media screen and (max-width: 768px) {
  .p-contact__label {
    font-size: calc(1.4375rem + ((1vw - 3.2px) * 0.6696));
    text-align: left;
  }
}

.p-contact__label::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  background-color: #f4e827;
}

.p-contact__required {
  max-width: 50%;
  margin-left: 10px;
  padding: 0 23px;
  border-radius: 4px;
  background-color: #c6403a;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
}

.p-contact__select {
  width: 100%;
  position: relative;
  margin-top: 30px;
  border: 1px solid #1a1a1a;
  border-radius: 6px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .p-contact__select {
    margin-top: 20px;
  }
}

.p-contact__select::after {
  content: '\f078';
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  color: #1a1a1a;
  font-family: "Font Awesome 5 Free";
  font-size: 19px;
  pointer-events: none;
}

.p-contact__select-inner {
  width: 100%;
  padding: 28px;
  color: #878787;
  font-size: 20px;
}

.p-contact__select-option {
  color: #1a1a1a;
}

.p-contact__input {
  width: 100%;
  margin-top: 30px;
  padding: 28px;
  border: 1px solid #1a1a1a;
  border-radius: 6px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .p-contact__input {
    margin-top: 20px;
  }
}

.p-contact__textarea {
  width: 100%;
  margin-top: 30px;
  padding: 28px;
  border: 1px solid #1a1a1a;
  border-radius: 6px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .p-contact__textarea {
    margin-top: 20px;
  }
}

.p-contact__privacy {
  margin-top: 30px;
  text-align: left;
  text-decoration: underline;
  opacity: .9;
}

.p-contact__privacy-small {
  font-size: 12px;
  font-weight: 300;
  text-align: left;
}

.p-contact__btn {
  margin: 50px auto 0;
}

@media screen and (max-width: 768px) {
  .p-contact__btn {
    max-width: 100%;
  }
}

.p-contact .has-error .p-contact__input,
.p-contact .has-error .p-contact__textarea {
  border-color: #c6403a;
}

.p-contact .has-error.p-contact__select {
  border-color: #c6403a;
}

.p-contact .validation-block {
  color: #c6403a;
  text-align: left;
}

.p-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 91px;
  padding: 0 31px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #1a1a1a;
}

@media screen and (max-width: 768px) {
  .p-header {
    height: 80px;
    padding: 0 20px;
    box-shadow: none;
  }
}

.p-header__logos {
  flex: none;
}

@media screen and (max-width: 768px) {
  .p-header__logos {
    width: 301px;
    flex-shrink: 1;
  }
}

.p-header__logo {
  width: 183px;
}

@media screen and (max-width: 768px) {
  .p-header__logo {
    width: 60.79%;
  }
}

.p-header__uep3 {
  width: 96px;
  margin: 0 39px;
}

@media screen and (max-width: 768px) {
  .p-header__uep3 {
    width: 22.9%;
    margin: 0 6%;
  }
}

.p-header__btn {
  width: 236px;
  margin-right: 30px;
  border: none;
}

@media screen and (max-width: 768px) {
  .p-header__btn {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 50%;
    height: 50px;
    margin: 0;
    border-radius: 0;
    line-height: 50px;
  }
}

.p-header__phone {
  width: 52px;
}

.p-header__right {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .p-header__right {
    right: auto;
    left: 0;
  }
}

.p-header__humberger {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .p-header__humberger {
    margin-left: 20px;
  }
}

.p-humberger {
  font-size: 0;
  /*ハンバーガーアイコンをCSSだけで表現*/
  /*チェックが入ったらハンバーガーアイコン変更*/
}

.p-humberger__icon {
  display: inline-block;
  width: 35px;
  height: 40px;
  vertical-align: middle;
}

.p-humberger__icon-line {
  display: block;
  position: absolute;
  transition: all 0.3s;
  transform: rotate(0deg);
  width: 34px;
  /*長さ*/
  height: 6px;
  /*線の太さ*/
  background: #f4e827;
  color: #f4e827;
  cursor: pointer;
}

.p-humberger__icon-line--middle {
  transform: translateY(12px);
  background: #fff;
}

.p-humberger__icon-line--bottom {
  background-color: transparent;
  font: 16px/23px Anton;
  height: 16px;
  transform: translateY(22px);
}

.p-humberger__input {
  position: absolute;
  top: 10px;
  right: 10px;
}

.p-humberger__input:checked ~ .p-humberger__icon .p-humberger__icon-line--top {
  transform: rotate(45deg);
}

.p-humberger__input:checked ~ .p-humberger__icon .p-humberger__icon-line--middle {
  transform: rotate(-45deg);
}

.p-main {
  padding: 136px 14.1% 135px;
  background-color: gray;
  font-size: 32px;
  line-height: 38px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .p-main {
    padding: 124px 0 193px;
  }
  .p-main__inner {
    padding: 23px 5.2%;
    background-color: rgba(255, 255, 255, 0.65);
  }
}

.p-main__uep3-logo {
  width: 182px;
}

@media screen and (max-width: 768px) {
  .p-main__uep3-logo {
    width: 217px;
  }
}

.p-main__text {
  margin: 22px 0 60px;
}

@media screen and (max-width: 768px) {
  .p-main__text {
    margin: 15px 0 21px;
  }
}

.p-main__logo {
  width: 313px;
}

@media screen and (max-width: 768px) {
  .p-main__logo {
    width: 341px;
    max-width: 100%;
  }
}

.p-main__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 435px;
  height: 103px;
  margin-bottom: 80px;
  border-radius: 52px;
  color: #1a1a1a;
  font-size: 20px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .p-main__btn {
    width: 100%;
    max-width: 435px;
    margin: 55px 0 25px;
    padding: 0 30px;
  }
}

/*-----------------------------------------------
|   追加分
-----------------------------------------------*/
.p-main {
  background: #ffffff;
}

.p-main__inner {
  position: relative;
  z-index: 2;
}

/*-----------------------------------------------
|   Showcase
-----------------------------------------------*/
.devices-wrapper {
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 56.25rem;
  height: 56.25rem;
  z-index: -1;
}

@media (min-width: 768px) {
  .devices-wrapper {
    left: 60%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 992px) {
  .devices-wrapper {
    left: 50%;
    width: 68.75rem;
    height: 68.75rem;
  }
}

@media (min-width: 1200px) {
  .devices-wrapper {
    left: 40%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 1400px) {
  .devices-wrapper {
    left: 25%;
    width: 100rem;
    height: 100rem;
  }
}

.devices-wrapper .device {
  width: 100% !important;
  border: 8px solid #000;
  width: 6.25rem;
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  -o-transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1), -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
}

.devices-wrapper [class*="iphone-"] {
  width: 10%;
  position: absolute;
}

.devices-wrapper [class*="iphone--l-"] {
  width: 29.5%;
  position: absolute;
}

.devices-wrapper [class*="ipad--l-"] {
  width: 28%;
  position: absolute;
}

.devices-wrapper [class*="ipad--p-"] {
  width: 21%;
  position: absolute;
}

.devices-wrapper [class*="macbook-"] {
  width: 44%;
  position: absolute;
}

.devices-wrapper [class*="iphone-"] {
  width: 10%;
  position: absolute;
}

.devices-wrapper [class*="iphone--l-"] {
  width: 29.5%;
  position: absolute;
}

.devices-wrapper [class*="ipad--l-"] {
  width: 28%;
  position: absolute;
}

.devices-wrapper [class*="ipad--p-"] {
  width: 21%;
  position: absolute;
}

.devices-wrapper [class*="macbook-"] {
  width: 44%;
  position: absolute;
}

.devices-wrapper .iphone-1 {
  left: 27%;
  top: 40%;
}

.devices-wrapper .iphone-2 {
  left: 69%;
  top: 40%;
}

.devices-wrapper .iphone-3 {
  left: 55%;
  top: -14%;
}

.devices-wrapper .iphone--l-1 {
  left: 42%;
  top: 93%;
}

.devices-wrapper .iphone-4 {
  left: 88%;
  top: 63%;
}

.devices-wrapper .ipad--l-1 {
  left: -3%;
  top: 40%;
}

.devices-wrapper .ipad--l-2 {
  left: 39%;
  top: 40%;
}

.devices-wrapper .ipad--l-3 {
  left: 81%;
  top: 40%;
}

.devices-wrapper .ipad--p-1 {
  left: 69%;
  top: 10%;
}

.devices-wrapper .macbook-1 {
  left: 42%;
  top: 63%;
}

.devices-wrapper .macbook-2 {
  left: 22%;
  top: 10%;
}

.p-main {
  overflow: hidden;
  position: relative;
}

.devices-wrapper {
  z-index: 0;
}

.p-menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 91px;
  left: 0;
  z-index: 4;
  /*最前面に*/
  width: 100%;
  height: calc(100% - 91px);
  margin: 0 auto;
  padding-bottom: 42px;
  overflow-y: auto;
  background: #1a1a1a;
  /*背景色*/
  color: #f4e827;
  font-size: 19px;
  transition: .3s ease-in-out;
  /*滑らかに表示*/
  -webkit-transform: translateX(-105%);
  transform: translateY(-40%);
  /*上に隠しておく*/
}

@media screen and (max-width: 768px) {
  .p-menu {
    top: 80px;
  }
}

.p-menu__list {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.p-menu__list-btn {
  width: 463px;
  margin: 133px auto 60px;
}

@media screen and (max-width: 768px) {
  .p-menu__list-btn {
    width: 280px;
  }
}

.p-menu__list-item {
  margin-bottom: 30px;
}

.p-menu__list-item-subtitle {
  margin: 0;
  color: #f4e827;
  font-size: 16px;
  line-height: 27px;
}

.p-menu__list-item-title {
  padding: 0;
  background: none;
  color: #fff;
  font-size: 40px;
  line-height: 59px;
}

@media screen and (max-width: 768px) {
  .p-menu__list-item-title {
    font-size: 30px;
    line-height: 44px;
  }
}

.p-menu.is-active {
  opacity: .95;
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  /*中身を表示（下へスライド）*/
}

.memberflow div::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-color: transparent transparent #c1c1c1 #c1c1c1;
  transform: rotate(-45deg);
  display: block;
  margin: 0px 0 30px 12px;
  padding-top: 0px;
}

.memberflow > div:last-child::after {
  content: none;
}

ul.flowcb {
  display: flex;
}

.flowcb li {
  width: 50%;
  list-style: none;
  text-align: center;
}

.flowcb li a {
  display: block;
  padding: 20px 10px;
}

.flowcb li:first-child a {
  background: #000;
  color: #fff;
}

.flowcb li:last-child a {
  background: #ffe600;
  color: #000;
}

@media screen and (max-width: 768px) {
  ul.flowcb {
    display: block;
  }
  .flowcb li {
    width: 100%;
    list-style: none;
    text-align: center;
  }
}

.precontact a {
  display: inline-block;
  margin-bottom: 50px;
  padding: 14px 20px;
  text-align: center;
  background: #000;
  color: #fff;
}

.price-list th {
  font-weight: bold !important;
  background: #212121 !important;
}

@media screen and (min-width: 768px) {
  .price-list .priceh4 {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: row;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .price-list .ared {
    display: inline;
    padding-left: 0.3rem;
  }
}

@media screen and (max-width: 768px) {
  .price-list .priceh4 {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-bottom: 1rem;
  }
  .price-list .ared {
    display: block;
  }
}

.price-list .text_n1,
.price-list .text_n2 {
  margin: auto 1rem;
}

.price-list .datesale {
  color: #debc00;
}

.price-list .ared {
  text-decoration: underline;
  color: #f34040;
  font-size: 30px;
  font-weight: bold;
}

.price-list .scroll {
  margin-bottom: 30px;
  overflow: auto;
  /*tableをスクロールさせる*/
  white-space: nowrap;
  /*tableのセル内にある文字の折り返しを禁止*/
}

.price-list .scroll::-webkit-scrollbar {
  /*tableにスクロールバーを追加*/
  height: 5px;
}

.price-list .scroll::-webkit-scrollbar-track {
  /*tableにスクロールバーを追加*/
  background: #F1F1F1;
}

.price-list .scroll::-webkit-scrollbar-thumb {
  /*tableにスクロールバーを追加*/
  background: #BCBCBC;
}

.price-list .scroll th,
.price-list .scroll td {
  white-space: nowrap;
}

.price-list td,
.price-list th {
  /* border: 1px solid #0c0c0c; */
  border: 1px solid #0c0c0c;
  padding: 10px 15px;
  line-height: 2.2;
  background: #313131;
  color: #fff;
}

.price-list td a,
.price-list th a {
  color: #D90000;
}

.pb_headline.pb_font_family_type3 {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  text-align: center;
}

.practice-schedule .q_button {
  min-width: 200px;
  max-width: 90%;
  background-color: #535353;
  display: inline-block;
  vertical-align: middle;
  padding: .6em 1.3em .5em;
  font-weight: 400;
  font-size: 100%;
  color: #fff !important;
  margin: 0;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.practice-schedule .bt_green {
  background: #27ae60;
  color: #fff;
}

.practice-schedule .bt_blue {
  background: #2980b9;
  color: #fff;
}

.practice-schedule td,
.practice-schedule th {
  /* border: 1px solid #0c0c0c; */
  border: 1px solid #0c0c0c;
  padding: 10px 15px;
  line-height: 2.2;
  background: #313131;
  color: #fff;
}

.practice-schedule td a,
.practice-schedule th a {
  color: #D90000;
}

.practice-schedule .scroll {
  margin-bottom: 30px;
  overflow: auto;
  /*tableをスクロールさせる*/
  white-space: nowrap;
  /*tableのセル内にある文字の折り返しを禁止*/
}

.practice-schedule .scroll::-webkit-scrollbar {
  /*tableにスクロールバーを追加*/
  height: 5px;
}

.practice-schedule .scroll::-webkit-scrollbar-track {
  /*tableにスクロールバーを追加*/
  background: #F1F1F1;
}

.practice-schedule .scroll::-webkit-scrollbar-thumb {
  /*tableにスクロールバーを追加*/
  background: #BCBCBC;
}

.practice-schedule .scroll th,
.practice-schedule .scroll td {
  white-space: nowrap;
}

.class .q_button {
  min-width: 200px;
  max-width: 90%;
  background-color: #535353;
  display: inline-block;
  vertical-align: middle;
  padding: .6em 1.3em .5em;
  font-weight: 400;
  font-size: 100%;
  color: #fff !important;
  margin: 0;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.class .bt_green {
  background: #27ae60;
  color: #fff;
}

.class .bt_blue {
  background: #2980b9;
  color: #fff;
}

.coach-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .coach-block {
    display: block;
  }
}

.coach-image {
  width: 45%;
  height: auto;
}

.coach-image img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .coach-image {
    width: 100%;
  }
}

.coach-text {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .coach-text {
    width: 100%;
  }
}

.esforcoplace table {
  width: 100%;
  margin-bottom: 30px;
}

.esforcoplace th {
  border: 1px solid #0c0c0c;
  padding: 10px 15px;
  line-height: 2.2;
  background: #313131;
  color: #fff;
}

.esforcoplace td {
  border: 1px solid #0c0c0c;
  padding: 10px 15px;
  line-height: 2.2;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.p-reserve {
  background-color: #f4e827;
  padding: 100px 0 60px;
}

.p-reserve__img {
  display: block;
  width: 116px;
  margin: 0 auto 30px;
}

.p-reserve__title {
  background: linear-gradient(transparent 66%, #fff 66%);
  font-size: 60px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .p-reserve__title {
    font-size: calc(1.9375rem + ((1vw - 3.2px) * 6.4732));
  }
}

.p-reserve__type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 840px;
  margin: 65px auto 0;
}

@media screen and (max-width: 768px) {
  .p-reserve__type-list {
    margin: 45px auto 0;
  }
}

.p-reserve__type {
  margin: 0 20px 40px;
}

@media screen and (max-width: 768px) {
  .p-reserve__type {
    margin: 0 10px 20px;
  }
}

.p-single {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  background-color: #fff;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  font-weight: normal;
  text-align: left;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .p-single {
    padding-bottom: 50px;
  }
}

.p-single p,
.p-single ul,
.p-single ol,
.p-single dl {
  margin-bottom: 20px;
}

.p-single h2 {
  border-left: 10px solid #f4e827;
  margin: 0 0 36px;
  padding: 0 10px;
  font-size: 24px;
  line-height: 36px;
}

.p-single img {
  max-width: 100%;
  width: unset;
  height: auto;
}

.p-single__title {
  margin: 0 8.33%;
  padding-top: 80px;
  max-width: 1000px;
  font-size: 40px;
  line-height: 60px;
}

@media screen and (max-width: 768px) {
  .p-single__title {
    margin: 50px 20px 0;
    padding-top: 50px;
    font-size: 32px;
    line-height: 48px;
  }
}

.p-single__cat {
  display: inline-block;
  margin: 30px 20px 30px 8.33%;
  padding: 0 10px;
  background-color: #1a1a1a;
  font-size: 12px;
  line-height: 24px;
  color: #f4e827;
}

@media screen and (max-width: 768px) {
  .p-single__cat {
    margin: 30px 20px;
  }
}

.p-single__date {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}

.p-single__main {
  max-width: 1000px;
  margin: 0 8.33%;
  padding-top: 60px;
  border-top: 4px solid #1a1a1a;
}

@media screen and (max-width: 768px) {
  .p-single__main {
    margin: 0 20px;
  }
}

.p-single__sub-title {
  border-left: 10px solid #f4e827;
  margin: 0 0 36px;
  padding: 0 10px;
  font-size: 24px;
  line-height: 36px;
}

.p-single__btns {
  display: flex;
  justify-content: center;
  margin: 80px;
}

.p-single__btns-item {
  width: 300px;
  position: relative;
  margin: 0 15px;
}

.p-single__btns-item--prev::before, .p-single__btns-item--next::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #f4e827;
  font-family: "Font Awesome 5 Free";
  font-size: 19px;
}

.p-single__btns-item--prev::before {
  content: '\f053';
  left: 30px;
}

.p-single__btns-item--next::after {
  content: '\f054';
  right: 30px;
}

@media screen and (max-width: 768px) {
  .p-single__btns {
    display: block;
    margin: 50px 0;
  }
  .p-single__btns-item {
    max-width: 100%;
    margin: 0 auto;
  }
  .p-single__btns-item--prev {
    margin-bottom: 30px;
  }
}

/*-----------------------------
ギャラリー
------------------------------*/
.gallery {
  /** ギャラリーを囲むボックス **/
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px !important;
  overflow: hidden;
}

.gallery br {
  display: none;
}

/** 自動で挿入される clearfix の余白解除 **/
.gallery-item {
  /** 画像共通のスタイル **/
  margin-bottom: 0 !important;
}

.gallery-icon {
  /** 画像を囲む dt のスタイル **/
  text-align: center;
}

.gallery-icon img {
  width: 100%;
  height: auto;
  margin-bottom: 10px !important;
}

.gallery-caption {
  /** キャプション **/
  color: #222;
  font-size: 12px;
  margin: 0 0 10px;
  text-align: center;
}

.gallery-columns-1 .gallery-item {
  /** カラムなし **/
  width: 100%;
  margin-right: 0;
}

.gallery-columns-2 .gallery-item {
  /** 2カラム **/
  width: 49%;
}

.gallery-columns-3 .gallery-item {
  /** 3カラム **/
  width: 33%;
}

.gallery-columns-3::after {
  display: block;
  content: "";
  width: 33%;
}

.gallery-columns-4::before {
  display: block;
  content: "";
  width: 24%;
  order: 1;
}

.gallery-columns-4::after {
  display: block;
  content: "";
  width: 24%;
}

.gallery-columns-4 .gallery-item {
  /** 4カラム **/
  width: 24%;
}

@media screen and (max-width: 768px) {
  /* 768px以下用の記述 */
  .gallery-columns-3 .gallery-item,
  .gallery-columns-4 .gallery-item {
    width: 49%;
  }
}

@media screen and (max-width: 480px) {
  /* 768px以下用の記述 */
  .gallery-columns-2 .gallery-item,
  .gallery-columns-3 .gallery-item,
  .gallery-columns-4 .gallery-item {
    width: 100%;
  }
}

.p-slider {
  margin-bottom: 74px;
}

@media screen and (max-width: 768px) {
  .p-slider {
    margin: 50px 0;
  }
}

.p-slider__inner {
  position: relative;
  margin-top: -30px;
  padding: 0 calc(1.25rem + ((1vw - 7.68px) * 32.0313));
}

@media screen and (max-width: 768px) {
  .p-slider__inner {
    margin-top: 0px;
    padding: 0 calc(0.625rem + ((1vw - 3.2px) * 7.8125));
  }
}

.p-slider__slide-btn {
  position: absolute;
  top: 35%;
  width: 20px;
}

.p-slider__slide-btn--prev {
  left: 0;
}

.p-slider__slide-btn--next {
  right: 0;
}

.p-slider__list {
  display: flex;
  align-items: center;
  text-align: left;
}

.p-slider__item {
  margin: 0 8.5px;
}

.p-slider__more-btn {
  margin: 52px auto 0;
}

.slide-arrow {
  width: 20px;
}

.prev-arrow {
  margin-right: calc(1.0625rem + ((1vw - 7.68px) * 2.1701));
}

@media screen and (min-width: 1921px) {
  .prev-arrow {
    margin-right: 42px;
  }
}

@media screen and (max-width: 768px) {
  .prev-arrow {
    margin-right: calc(1.0625rem + ((1vw - 3.2px) * 2.9018));
  }
}

.next-arrow {
  margin-left: calc(1.0625rem + ((1vw - 7.68px) * 2.1701));
}

@media screen and (min-width: 1921px) {
  .next-arrow {
    margin-left: 42px;
  }
}

@media screen and (max-width: 768px) {
  .next-arrow {
    margin-left: calc(1.0625rem + ((1vw - 3.2px) * 2.9018));
  }
}

.ugajin-cup-archive-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ugajin-cup-archive-list li {
  margin-bottom: 15px;
  width: 49%;
  height: auto;
}

@media screen and (max-width: 480px) {
  .ugajin-cup-archive-list li {
    width: 100%;
  }
}

.ugajin-cup-archive-list li img {
  width: 100%;
  height: auto;
}

.ugajin-cup-next {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: right;
}

.ugajin-cup-next-date {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px !important;
  padding-bottom: 10px;
}

.ugajincup__date {
  display: inline-block;
  margin: 10px 20px 10px 8.33%;
}

.ugajincup-block {
  margin-top: 100px;
}

.sponsor {
  margin-top: 100px;
}

.sponsor-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sponsor-list li {
  margin-bottom: 20px;
  width: 49%;
  height: auto;
}

.sponsor-list li a {
  font-size: 20px;
}

.sponsor-list li a.no-pointer {
  pointer-events: none;
}

@media screen and (max-width: 480px) {
  .sponsor-list li {
    width: 100%;
  }
}

.u-flex-wrap--no {
  flex-wrap: nowrap;
}

.u-flex {
  display: flex;
  justify-content: space-between;
}

.u-flex.even {
  flex-direction: row-reverse;
}

.u-flex .u-flex-image {
  margin-bottom: 50px;
  width: 40%;
  height: auto;
}

.u-flex .u-flex-image img {
  width: 100%;
  height: auto;
}

.u-flex .u-flex-text {
  margin-bottom: 50px;
  width: 55%;
}

@media screen and (max-width: 768px) {
  .u-flex {
    flex-direction: column;
  }
  .u-flex.even {
    flex-direction: column;
  }
  .u-flex .u-flex-image {
    margin-bottom: 30px;
    width: 100%;
  }
  .u-flex .u-flex-text {
    margin-bottom: 30px;
    width: 100%;
  }
}

.u-margin-bottom10 {
  margin-bottom: 10px;
}

.u-margin-bottom20 {
  margin-bottom: 20px;
}

.u-margin-bottom40 {
  margin-bottom: 40px;
}

.u-margin-bottom60 {
  margin-bottom: 60px;
}

.u-margin-x20 {
  margin-right: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .u-only-pc {
    display: none;
  }
}

.u-only-sp {
  display: none;
}

@media screen and (max-width: 768px) {
  .u-only-sp {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .u-only-sp--inline {
    display: inline;
  }
}

@media screen and (max-width: 768px) {
  .u-only-sp--flex {
    display: flex;
  }
}

.u-padding-t80 {
  padding-top: 80px;
}

.u-padding-b0 {
  padding-bottom: 0;
}

.u-padding-b100 {
  padding-bottom: 100px;
}
