.p-contact {
  margin: auto;
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 100px 20px;
  }

  &__form {
    max-width: 1000px;
    margin: auto;
  }

  &__text {
    margin: 60px auto 80px;
    font-size: 20px;
    line-height: 40px;
  }

  &__msg {
    margin: 50px 0 30px;
  }

  &__dl-btn {
    width: 400px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin-top: 50px;
    font-size: 26px;

    @media screen and (max-width: 768px) {
      font-size: calc(1.4375rem + ((1vw - 3.2px) * 0.6696));
      text-align: left;
    }

    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 26px;
      height: 26px;
      margin-right: 10px;
      background-color: $color-yellow;
    }
  }

  &__required {
    max-width: 50%;
    margin-left: 10px;
    padding: 0 23px;
    border-radius: 4px;
    background-color: $color-red;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
  }


  &__select {
    width: 100%;
    position: relative;
    margin-top: 30px;
    border: 1px solid $color-black;
    border-radius: 6px;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    &::after {
      content: '\f078';
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      color: $color-black;
      font-family: "Font Awesome 5 Free";
      font-size: 19px;
      pointer-events: none;
    }

    &-inner {
      width: 100%;
      padding: 28px;
      color: $color-placeholder;
      font-size: 20px;
    }

    &-option {
      color: $color-black;
    }
  }

  &__input {
    width: 100%;
    margin-top: 30px;
    padding: 28px;
    border: 1px solid $color-black;
    border-radius: 6px;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__textarea {
    width: 100%;
    margin-top: 30px;
    padding: 28px;
    border: 1px solid $color-black;
    border-radius: 6px;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__privacy {
    margin-top: 30px;
    text-align: left;
    text-decoration: underline; 
    opacity: .9;
  }

  &__privacy-small {
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }

  &__btn {
    margin: 50px auto 0;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .has-error {
    .p-contact__input,
    .p-contact__textarea,
    {
      border-color: $color-red;
    }

    &.p-contact__select {
      border-color: $color-red;
    }
  }

  .validation-block {
    color: $color-red;
    text-align: left;
  }
}
