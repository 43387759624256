.p-about-group {
  padding: 100px 0;
  &__text {
    margin: 60px auto 80px;
    font-size: 20px;
    line-height: 40px;
    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &__effort-dream {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1000px;
    max-height: 290px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      display: block;
      max-height: none;
      padding: 0 20px;
    }

    &-dt {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 74px;
      line-height: 74px;
      font-size: 30px;

      &--black {
        background-color: $color-black;
        color: $color-yellow;
      }

      &--yellow {
        background-color: $color-yellow;
        color: $color-black;
      }
      &--white {
        color: #fff;
      }

      &--en {
        font-size: 40px;
        font-weight: normal;
        font-family: 'Anton', sans-serif;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &-dd {
      width: 50%;
      padding: 41px 50px 0;
      font-weight: 300;
      font-size: 20px;
      line-height: 34px;
      text-align: left;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 34px 20px 50px;
      }
    }
  }

  &__teikan {
    margin: 80px auto;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  &__btn-list {
    @media screen and (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  &__menu {
    max-width: 1000px;
    margin: 40px auto 0;
    padding: 31px 35px;
    background-color: $color-gray;

    &-list {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &-item {
      width: 50%;
      padding: 29px 0 9px;

      &:first-child {
        padding-right: 45.5px;
        border-right: 2px solid #fff;
      }
      &:last-child {
        padding-left: 45.5px;
      }

      @media screen and (max-width: 768px) {
        width: 420px;
        max-width: 100%;
        margin: auto;

        &:first-child {
          border: none;
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }

    &-title {
      margin-bottom: 50px;
      font-size: 24px;
    }

    &-btn {
      width: 100%;
      margin-bottom: 20px;
    }
  }

}
