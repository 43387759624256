.c-sns {
  display: flex;
  align-items: center;
  &__btn {
    margin-right: 29px;
    font-size: 55px;

    &:first-child {
      margin-left: 14px;
    }

    &--twitter {
      font-size: 48px;
    }

    &--youtube {
      font-size: 46px;
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 50px;
    left: 0;
    z-index: 3;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: rgba(26,26,26,.8);

    &__btn {
      color: #fff;
      font-size: 26px;
      line-height: 40px;

      &--twitter {
        font-size: 19px;
      }

      &--youtube {
        font-size: 18px;
      }
    }
  }
}
