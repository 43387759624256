.u-margin {
  &-bottom10 {
    margin-bottom: 10px;
  }

  &-bottom20 {
    margin-bottom: 20px;
  }

  &-bottom40 {
    margin-bottom: 40px;
  }

  &-bottom60 {
    margin-bottom: 60px;
  }

  &-x20 {
    margin-right: 20px;
    margin-left: 20px;
  }
}