.memberflow div::after {
	content: '';
	width: 20px;
	height: 20px;
	border: 4px solid;
	border-color: transparent transparent #c1c1c1 #c1c1c1;
	transform: rotate(-45deg);
	display: block;
	margin: 0px 0 30px 12px;
	padding-top: 0px;
}

.memberflow>div:last-child::after {
	content: none;
}

ul.flowcb {
	display: flex;
}

.flowcb li {
	width: 50%;
	list-style: none;
	text-align: center;
}

.flowcb li a {
	display: block;
	padding: 20px 10px;
}

.flowcb li:first-child a {
	background: #000;
	color: #fff;

}

.flowcb li:last-child a {
	background: #ffe600;
	color: #000;
}


@media screen and (max-width: 768px) {
	ul.flowcb {
		display: block;
	}

	.flowcb li {
		width: 100%;
		list-style: none;
		text-align: center;
	}
}

.precontact a {
	display: inline-block;
	margin-bottom: 50px;
	padding: 14px 20px;
	text-align: center;
	background: #000;
	color: #fff;
}

.price-list {
	th {
		font-weight: bold !important;
		background: #212121 !important;
	}

	@media screen and (min-width: 768px) {
		.priceh4 {
			text-align: center;
			display: flex;
			justify-content: center;
			flex-flow: row;
			margin-bottom: 1rem;
			margin-top: 2rem;
		}

		.ared {
			display: inline;
			padding-left: 0.3rem;
		}
	}

	@media screen and (max-width: 768px) {
		.priceh4 {
			text-align: center;
			display: flex;
			justify-content: center;
			flex-flow: column;
			margin-bottom: 1rem;
		}

		.ared {
			display: block;
		}
	}

	.text_n1,
	.text_n2 {
		margin: auto 1rem;
	}

	.datesale {
		color: #debc00;
	}

	.ared {
		text-decoration: underline;
		color: #f34040;
		font-size: 30px;
		font-weight: bold;
	}

	.scroll {
		margin-bottom: 30px;
		overflow: auto;
		/*tableをスクロールさせる*/
		white-space: nowrap;
		/*tableのセル内にある文字の折り返しを禁止*/
	}

	.scroll::-webkit-scrollbar {
		/*tableにスクロールバーを追加*/
		height: 5px;
	}

	.scroll::-webkit-scrollbar-track {
		/*tableにスクロールバーを追加*/
		background: #F1F1F1;
	}

	.scroll::-webkit-scrollbar-thumb {
		/*tableにスクロールバーを追加*/
		background: #BCBCBC;
	}

	.scroll th,
	.scroll td {
		white-space: nowrap;
	}

	td,
	th {
		/* border: 1px solid #0c0c0c; */
		border: 1px solid #0c0c0c;
		padding: 10px 15px;
		line-height: 2.2;
		background: #313131;
		color: #fff;

		a {
			color: #D90000;
		}
	}
}

.pb_headline.pb_font_family_type3 {
	margin-bottom: 30px;
	font-size: 20px;
	font-weight: 700;
	color: #333333;
	text-align: center;
}

.practice-schedule {
	.q_button {
		min-width: 200px;
		max-width: 90%;
		background-color: #535353;
		display: inline-block;
		vertical-align: middle;
		padding: .6em 1.3em .5em;
		font-weight: 400;
		font-size: 100%;
		color: #fff !important;
		margin: 0;
		text-decoration: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
		transition: all 0.3s ease-in-out 0s;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
	}

	.bt_green {
		background: #27ae60;
		color: #fff;
	}

	.bt_blue {
		background: #2980b9;
		color: #fff;
	}

	td,
	th {
		/* border: 1px solid #0c0c0c; */
		border: 1px solid #0c0c0c;
		padding: 10px 15px;
		line-height: 2.2;
		background: #313131;
		color: #fff;

		a {
			color: #D90000;
		}
	}

	.scroll {
		margin-bottom: 30px;
		overflow: auto;
		/*tableをスクロールさせる*/
		white-space: nowrap;
		/*tableのセル内にある文字の折り返しを禁止*/
	}

	.scroll::-webkit-scrollbar {
		/*tableにスクロールバーを追加*/
		height: 5px;
	}

	.scroll::-webkit-scrollbar-track {
		/*tableにスクロールバーを追加*/
		background: #F1F1F1;
	}

	.scroll::-webkit-scrollbar-thumb {
		/*tableにスクロールバーを追加*/
		background: #BCBCBC;
	}

	.scroll th,
	.scroll td {
		white-space: nowrap;
	}
}

.class {
	.q_button {
		min-width: 200px;
		max-width: 90%;
		background-color: #535353;
		display: inline-block;
		vertical-align: middle;
		padding: .6em 1.3em .5em;
		font-weight: 400;
		font-size: 100%;
		color: #fff !important;
		margin: 0;
		text-decoration: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
		transition: all 0.3s ease-in-out 0s;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
	}

	.bt_green {
		background: #27ae60;
		color: #fff;
	}

	.bt_blue {
		background: #2980b9;
		color: #fff;
	}
}

.coach-block {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;

	@media screen and (max-width: 768px) {
		display: block;
	}
}

.coach-image {
	width: 45%;
	height: auto;

	img {
		max-width: 100%;
		height: auto;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.coach-text {
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.esforcoplace {
	table {
		width: 100%;
		margin-bottom: 30px;
	}

	th {
		border: 1px solid #0c0c0c;
		padding: 10px 15px;
		line-height: 2.2;
		background: #313131;
		color: #fff;
	}

	td {
		border: 1px solid #0c0c0c;
		padding: 10px 15px;
		line-height: 2.2;
	}
}

.youtube {
	position: relative;
	width: 100%;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}