.ugajin-cup-archive-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    margin-bottom: 15px;
    width: 49%;
    height: auto;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.ugajin-cup-next {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: right;
}

.ugajin-cup-next-date {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px !important;
  padding-bottom: 10px;
}

.ugajincup__date {
  display: inline-block;
  margin: 10px 20px 10px 8.33%;
}

.ugajincup-block {
  margin-top: 100px;
}

.sponsor {
  margin-top: 100px;
}

.sponsor-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    margin-bottom: 20px;
    width: 49%;
    height: auto;

    a {
      font-size: 20px;

      &.no-pointer {
        pointer-events: none;
      }
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
}