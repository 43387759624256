.c-card {
  max-width: 186px;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  font-size: 0;
  font-weight: bold;
  text-align: left;

  &__img {
    width: 100%;
  }

  &__cat {
    display: inline-block;
    margin: 10px 0 8px;
    padding: 0 10px;
    background-color: $color-black;
    font-size: 12px;
    line-height: 24px;
    color: $color-yellow;
  }

  &__date {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__text {
    font-size: 16px;
    font-weight: normal;
  }
}
