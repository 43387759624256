.p-archive {
	padding: 100px 0 100px;

	@media screen and (max-width: 768px) {
		padding: 100px 20px 100px;
	}

	&__title {
		margin-bottom: 80px;
	}

	&__cats {
		margin: -10px auto 60px;

		@media screen and (max-width: 768px) {
			width: 100%;
			padding-right: calc(1.25rem + ((1vw - 3.2px) * 25.6696));
			padding-left: calc(1.25rem + ((1vw - 3.2px) * 25.6696));
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 1017px;
		margin: 0 auto;

		&--top {
			flex-wrap: nowrap;
		}

		@media screen and (max-width: 768px) {
			line-height: 32px;

			&--top {
				flex-wrap: wrap;
			}

			&::after,
			&::before {
				content: '';
				display: block;
				flex: 0 1 29%;
				margin: 0 8.5px;
			}

			&::before {
				order: 1;
			}
		}

		@media screen and (max-width: 480px) {

			&::after,
			&::before {
				flex: 0 1 43%;
			}
		}

	}

	&__item {
		margin: 0 8.5px 52px;

		@media screen and (max-width: 768px) {
			flex: 0 1 29%;
		}

		@media screen and (max-width: 480px) {
			max-width: none;
			flex: 0 1 43%;
		}

		&--top {
			@media screen and (max-width: 480px) {
				flex: 0 1 28%;
			}

			@media screen and (max-width: 414px) {
				flex: 0 1 98%;
			}
		}

	}

	&__more {
		max-width: 1000px;
		margin: 0 auto;

		&-btn {
			margin-left: auto;

			@media screen and (max-width: 768px) {
				margin-right: auto;
			}
		}
	}

	&__pagination {
		display: flex;
		justify-content: center;
		margin-top: 30px;
		font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;

		&-item {
			background-color: #fff;
			line-height: 50px;

			&:not(:last-child) {
				margin-right: 20px;
			}

			&.is-active {
				background-color: $color-yellow;
			}

			&-inner {
				display: block;
				padding: 0 20px;
			}

			@media screen and (max-width: 768px) {
				line-height: calc(16px + 5.2vw);

				&:not(:last-child) {
					margin-right: 2.7%;
				}

				&-inner {
					padding: 0 2.6vw;
				}
			}


		}
	}
}