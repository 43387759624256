.p-about {
	padding: 100px 0;
	background-color: $color-gray;

	&__sub-title-img {
		transform: translateY(-50%);

		&--logo {
			width: 413px;
		}

		&--uep3 {
			width: 291px;
		}

		@media screen and (max-width: 768px) {
			max-width: 100%;
		}
	}

	&__msg {
		margin: 60px 0 153px;
		font-size: 20px;
		line-height: 40px;
	}

	&__inner {
		max-width: 1200px;
		margin: 0 auto 207px;
		padding: 0 100px / 1920px * 100 + 0% 100px;
		background-color: #fff;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__ss-title {
		margin: 60px 0 30px;
		border-bottom: 6px solid $color-yellow;
		font-size: 26px;
		line-height: 44px;
		text-align: left;

		&--sp {
			@media screen and (max-width: 768px) {
				margin-top: 20px;
			}
		}
	}

	&__text {
		margin-bottom: 20px;
		font-weight: 300;
		text-align: left;
	}

	&__contact-btn {
		width: auto;
		max-width: 900px;
		height: 88px;
		margin: 40px auto 0;
		border-radius: 44px;
		font-size: 27px;
		line-height: 88px;

		@media screen and (max-width: 768px) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 20px;
			font-size: 22px;
			line-height: 1;
		}
	}

	&__philosophy-img {
		max-width: 846px;
		margin: 30px auto 50px;
	}

	&__coach-img {
		max-width: 1000px;
		margin: 0 auto;
	}

	&__faq {
		text-align: left;

		&-q {
			display: flex;
			align-items: center;
			padding: 8px 30px;
			border: 1px solid $color-black;
			margin-top: 20px;

			@media screen and (max-width: 768px) {
				font-size: 20px;
			}

			&::before {
				content: 'Q';
				margin-right: 30px;
				color: $color-yellow;
				font-family: 'Anton', sans-serif;
				font-size: 40px;
				font-weight: normal;
				line-height: 59px;
			}

			&::after {
				content: '\f078';
				margin-left: auto;
				color: $color-yellow;
				font-family: "Font Awesome 5 Free";
				font-size: 19px;
			}
		}

		&-a {
			display: none;
			padding: 30px;
			border: 1px solid $color-black;
			border-top: none;
			background-color: $color-light-gray;
			font-family: "Hiragino Sans GB", "Hiragino Sans GB W3", "STXihei", "Microsoft YaHei", "微软雅黑", 'SimSun', "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic Medium", "Yu Gothic", "Meiryo", "Arial", sans-serif;
			webkit-font-smoothing: antialiased;
			font-weight: 300;
		}
	}

	&__base-price {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		border: 1px solid $color-black;
		border-bottom: none;
		line-height: 66px;

		&-dt {
			width: 31.4%;
			background-color: $color-black;
			color: #fff;
		}

		&-dd {
			width: 68.6%;
			border-bottom: 1px solid $color-black;

			+.p-about__base-price-dt {
				border-top: 1px solid #fff;
			}
		}

		@media screen and (max-width: 768px) {
			font-size: 14px;
			line-height: 45px;
			margin-bottom: 20px;

			&-dd {
				padding-left: 19px;
				text-align: left;
			}
		}
	}

	&__table {
		width: 100%;
		line-height: 60px;
		table-layout: fixed;

		&-head {
			border: 1px solid $color-black;
			background-color: $color-black;
			color: #fff;

			&:not(:last-child) {
				border-right: 1px solid #fff;
			}
		}

		&-body {
			border: 1px solid $color-black;
		}

		@media screen and (max-width: 768px) {
			font-size: 14px;
			line-height: 1;

			&-head {
				height: 45px;
				vertical-align: middle;
			}

			&-body {
				height: 45px;
				vertical-align: middle;
			}
		}
	}
}