.p-main {
  padding: 136px 14.1% 135px;
  background-color: gray;
  font-size: 32px;
  line-height: 38px;
  text-align: left;

  @media screen and (max-width: 768px) {
    padding: 124px 0 193px;

    &__inner {
      padding: 23px 5.2%;
      background-color: rgba(255,255,255,.65);
    }
  }

  &__uep3-logo {
    width: 182px;
    @media screen and (max-width: 768px) {
      width: 217px;
    }
  }

  &__text {
    margin: 22px 0 60px;
    @media screen and (max-width: 768px) {
      margin: 15px 0 21px;
    }
  }

  &__logo {
    width: 313px;
    @media screen and (max-width: 768px) {
      width: 341px;
      max-width: 100%;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 435px;
    height: 103px;
    margin-bottom: 80px;
    border-radius: 52px;
    color: $color-black;
    font-size: 20px;
    line-height: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 435px;
      margin: 55px 0 25px;
      padding: 0 30px;
    }
  }
}

/*-----------------------------------------------
|   追加分
-----------------------------------------------*/

.p-main{
	background: #ffffff;
}

.p-main__inner{
	position: relative;
	z-index: 2;
}

/*-----------------------------------------------
|   Showcase
-----------------------------------------------*/
.devices-wrapper {
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 56.25rem;
  height: 56.25rem;
  z-index: -1;
}

@media (min-width: 768px) {
  .devices-wrapper {
    left: 60%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 992px) {
  .devices-wrapper {
    left: 50%;
    width: 68.75rem;
    height: 68.75rem;
  }
}

@media (min-width: 1200px) {
  .devices-wrapper {
    left: 40%;
    width: 75rem;
    height: 75rem;
  }
}

@media (min-width: 1400px) {
  .devices-wrapper {
    left: 25%;
    width: 100rem;
    height: 100rem;
  }
}

.devices-wrapper .device {
  width: 100% !important;
  border: 8px solid #000;
  width: 6.25rem;
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  -o-transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1), -webkit-transform 2s cubic-bezier(0.77, 0, 0.18, 1);
}

.devices-wrapper [class*="iphone-"] {
  width: 10%;
  position: absolute;
}

.devices-wrapper [class*="iphone--l-"] {
  width: 29.5%;
  position: absolute;
}

.devices-wrapper [class*="ipad--l-"] {
  width: 28%;
  position: absolute;
}

.devices-wrapper [class*="ipad--p-"] {
  width: 21%;
  position: absolute;
}

.devices-wrapper [class*="macbook-"] {
  width: 44%;
  position: absolute;
}

.devices-wrapper [class*="iphone-"] {
  width: 10%;
  position: absolute;
}

.devices-wrapper [class*="iphone--l-"] {
  width: 29.5%;
  position: absolute;
}

.devices-wrapper [class*="ipad--l-"] {
  width: 28%;
  position: absolute;
}

.devices-wrapper [class*="ipad--p-"] {
  width: 21%;
  position: absolute;
}

.devices-wrapper [class*="macbook-"] {
  width: 44%;
  position: absolute;
}

.devices-wrapper .iphone-1 {
  left: 27%;
  top: 40%;
}

.devices-wrapper .iphone-2 {
  left: 69%;
  top: 40%;
}

.devices-wrapper .iphone-3 {
  left: 55%;
  top: -14%;
}

.devices-wrapper .iphone--l-1 {
  left: 42%;
  top: 93%;
}

.devices-wrapper .iphone-4 {
  left: 88%;
  top: 63%;
}

.devices-wrapper .ipad--l-1 {
  left: -3%;
  top: 40%;
}

.devices-wrapper .ipad--l-2 {
  left: 39%;
  top: 40%;
}

.devices-wrapper .ipad--l-3 {
  left: 81%;
  top: 40%;
}

.devices-wrapper .ipad--p-1 {
  left: 69%;
  top: 10%;
}

.devices-wrapper .macbook-1 {
  left: 42%;
  top: 63%;
}

.devices-wrapper .macbook-2 {
  left: 22%;
  top: 10%;
}


.p-main{
	overflow: hidden;
	position: relative;
}

.devices-wrapper{
	z-index: 0;
}