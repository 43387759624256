.c-effort {
  background-blend-mode: luminosity;
  background: no-repeat center/cover url("../img/effort_bg.jpg");
  background-color: $color-bg-blend-color;

  &__inner {
    padding: 100px 0;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
  }

  &__title {
    display: inline-block;
    width: 340px;
    line-height: 80px;
    color: #fff;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    &--en {
      margin-right: 17px;
      background-color: $color-black;
      font-size: 44px;
      font-weight: normal;
      font-family: 'Anton', sans-serif;

      @media screen and (max-width: 698px) {
        margin-right: 0
      }
    }

    &--jp {
      background-color: $color-yellow;
      color: $color-black;
      font-size: 28px;
    }

    &--color-white {
      color: #fff;
    }

    &--color-yellow {
      color: $color-yellow;
    }
  }

  &__movie {
    width: 1000px;
    max-width: 100%;
    margin: 80px auto 50px;
    
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__copy {
    display: inline-block;
    width: 284px;
    margin-right: 42px;
    vertical-align: middle;
    @media screen and (max-width: 768px) {
      width: 354px;
      max-width: calc(100% - 40px);
      margin: 0 20px 70px;
    }
  }

  &__msg {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    line-height: 31px;
    text-align: left;
    vertical-align: middle;
    @media screen and (max-width: 768px) {
      margin: 0 20px;
    }
  }

  &__msg-inner {
    display: flex;
    flex-direction: column;
  }

  &__sign {
    width: 191px;
    margin-left: auto;
  }
}
