.p-btn-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 50px auto 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  &::after {
    content: '';
    width: 100%; 
  }

  &__item {
    margin-bottom: 20px;
    width: calc(50% - 10px);

    &:nth-child(n+3) {
      order: 1;
    }
    &:nth-child(odd) {
      margin-right: 20px;
    }

    @media screen and (max-width: 768px) {
      width: 490px;
      max-width: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }

  &__btn {
    width: 100%;
  }

}


