.p-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 91px;
  padding: 0 31px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: $color-black;
  @media screen and (max-width: 768px) {
    height: 80px;
    padding: 0 20px;
    box-shadow: none;
  }

  &__logos {
    flex: none;
    @media screen and (max-width: 768px) {
      width: 301px;
      flex-shrink: 1;
    }
  }

  &__logo {
    width: 183px;
    @media screen and (max-width: 768px) {
      width: 60.79%;
    }
  }

  &__uep3 {
    width: 96px;
    margin: 0 39px;
    @media screen and (max-width: 768px) {
      width: 22.9%;
      margin: 0 6%;
    }
  }

  &__btn {
    width: 236px;
    margin-right: 30px;
    border: none;

    @media screen and (max-width: 768px) {
      display: block;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 3;
      width: 50%;
      height: 50px;
      margin: 0;
      border-radius: 0;
      line-height: 50px;
    }
  }

  &__phone {
    width: 52px;
  }

  &__right {
    margin-left: auto;
    @media screen and (max-width: 768px) {
      right: auto;
      left: 0;
    }
  }

  &__humberger {
    margin-left: 10px;
    @media screen and (max-width: 768px) {
      margin-left: 20px;
    }
  }

}
