.c-cats {
  max-width: 1000px;
  margin: auto;
  padding: 30px 0;
  background-color: #fff;

  &__title {
    font-size: 24px;
    font-family: 'Anton', sans-serif;
    font-weight: normal;
    line-height: 35px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", sans-serif;
  }

  &__list-item {
    margin: 0 15px;

    .is-active {
      color: $color-yellow;
    }
  }
}
