.c-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 82px;
  height: 82px;
  z-index: 3;

  @media screen and (max-width: 768px) {
    bottom: 104px;
    width: pxToVw(82, 768);
    height: pxToVw(82, 768);
  }

}
