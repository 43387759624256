.p-menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 91px;
  left: 0;
  z-index: 4;/*最前面に*/
  width: 100%;
  height: calc(100% - 91px);
  margin: 0 auto;
  padding-bottom: 42px;
  overflow-y: auto;
  background: $color-black;/*背景色*/
  color: $color-yellow;
  font-size: 19px;
  transition: .3s ease-in-out;/*滑らかに表示*/
  -webkit-transform: translateX(-105%);
  transform: translateY(-40%);/*上に隠しておく*/

  @media screen and (max-width: 768px) {
    top: 80px;
  }


  &__list {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &-btn {
      width: 463px;
      margin: 133px auto 60px;
      @media screen and (max-width: 768px) {
        width: 280px;
      }
    }

    &-item {
      margin-bottom: 30px;
      &-subtitle {
        margin: 0;
        color: $color-yellow;
        font-size: 16px;
        line-height: 27px;
      }
      &-title {
        padding: 0;
        background: none;
        color: #fff;
        font-size: 40px;
        line-height: 59px;
        @media screen and (max-width: 768px) {
          font-size: 30px;
          line-height: 44px;
        }
      }
    }
  }

  &.is-active {
    opacity: .95;
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);/*中身を表示（下へスライド）*/
  }
}
