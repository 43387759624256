.p-reserve {
  background-color: $color-yellow;
  padding: 100px 0 60px;

  &__img {
    display: block;
    width: 116px;
    margin: 0 auto 30px;
  }

  &__title {
    background: linear-gradient(transparent 66%, #fff 66%);
    font-size: 60px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: calc(1.9375rem + ((1vw - 3.2px) * 6.4732));
    }
  }

  &__type-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 840px;
    margin: 65px auto 0;

    @media screen and (max-width: 768px) {
      margin: 45px auto 0;
    }
  }

  &__type {
    margin: 0 20px 40px;

    @media screen and (max-width: 768px) {
      margin: 0 10px 20px;
    }
  }
}
