.u-padding {
  &-t80 {
    padding-top: 80px;
  }
  &-b0 {
    padding-bottom: 0;
  }
  &-b100 {
    padding-bottom: 100px;
  }
}
