.c-footer {
  border-top: 1px solid $color-footer-gray; 

  &__partners {
    margin: 100px auto 40px;
    padding: 0 30px 0;
    max-width: 1000px;

    @media screen and (max-width: 768px) {
      padding: 0 20px 0;
    }

    &-dt {
      border-bottom: 1px solid $color-black;
      font-family: 'Anton', sans-serif;
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
    }

    &-dd {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 45px 0;

      @media screen and (max-width: 768px) {
        padding: 66px 0 60px;
      }
    }

    &-item {
      width: auto;
      zoom: .4347;
      @media screen and (max-width: 768px) {
        margin: 0 42px 10px;
        zoom: .5;
      }
    }
  }

  &__footer {
    padding: 50px 0;
    background-color: $color-black;
    color: $color-footer-gray;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 50px 0 100px;
    }

    &-logo {
      width: 183px;
      margin-right: 30px;
    }

    &-uep3 {
      width: 102px;
    }

    &-title {
      margin: 20px 0 16px;
      font-family: 'Anton', sans-serif;
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
    }

    &-address {
      font-size: 15px;
      line-height: 26px;
    }

    &-copy {
      margin: 40px 0 52px;
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
    }
  }
}
