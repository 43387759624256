.p-access {
  padding-top: 100px;
  background-color: $color-gray;
  &__address {
    margin: 60px auto 22px;
    font-size: 20px;
  }

  &__access {
    margin-bottom: 60px;
    font-weight: 300;
  }
}
