.l-main {
  margin-top: 91px;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }

  &--gray {
    background-color: $color-gray;
  }

  &--single {
    padding: 80px 0 100px;
    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }
}
