// basic settings
$base_width: 1920;
$color-yellow: #f4e827;
$color-black: #1a1a1a;
$color-bg-blend-color: #e3e3e3;
$color-gray: #f2f2f2;
$color-light-gray: #fafafa;
$color-placeholder: #878787;
$color-red: #c6403a;
$color-footer-gray: #c1c1c1;

// px値をvw値に変換する
@function pxToVw($px_value, $base: $base_width) {
  @return (($px_value / $base) * 100) + vw;
}


*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

body {
  //font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif; 
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo", "Arial", sans-serif; 
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: $color-black;

  &.modal-open {
    overflow: hidden;
  }

  &.show-menu {
    overflow: hidden;
  }
}

.hiragino_sans {
  font-family: "Hiragino Sans", serif;
}
.hiragino_kaku {
  font-family: "Hiragino Kaku Gothic ProN", serif;
}
.yugothic {
  font-family: "Yu Gothic Medium", "Yu Gothic", serif;
}
.meiryo {
  font-family: "Meiryo", "Yu Gothic", serif;
}

.w {
  &__n {
    font-weight: normal;
  }
  &__b {
    font-weight: bold;
  }
  &__3 {
    font-weight: 300;
  }
  &__4 {
    font-weight: 400;
  }
  &__6 {
    font-weight: 600;
  }
  &__7 {
    font-weight: 700;
  }
}
