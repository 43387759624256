.u-only {
  &-pc {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &-sp {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }

    &--inline {
      @media screen and (max-width: 768px) {
        display: inline;
      }
    }

    &--flex {
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }
}
