.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0,0,0,0.2);
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	-o-transition: opacity .15s linear;
	transition: opacity .15s linear;

  &-dialog {
    position: relative;
    width: auto;
    margin: 100px auto; 
    padding: 50px 0;
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -125%);
    -ms-transform: translate(0, -125%);
    -o-transform: translate(0, -125%);
    transform: translate(0, -125%);
    background-color: #fff;
    font-size: 20px;
  }

	&.in {
		opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;

    .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
    }
	}

  &-footer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    
    .c-btn:first-child {
      margin-right: 30px;
    }
  }


  &-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;

    &.in {
      opacity: .5;
    }
  }
}
